import React from 'react';

function Navbar() {
  return (
    <nav className="navbar tc-navbar-style5">
      <div className="container justify-content-start">
        <div className="col-lg-3 d-lg-block">
          <div className="nav-links">
            <a href="tel:+16041111111" className="me-5">
              <i className="la la-tty cir-icon"></i>
              <span className="txt color-2626"> +1 604 111 1111 </span>
            </a>
            {/* <a href="#" className="">
              <span className="txt"> 3d tour </span>
            </a> */}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="logo text-center  mb-lg-0">
            <img src="/home5_residence/assets/images/logo.png" alt="" />
          </div>
        </div>
        {/* <div className="col-lg-3 text-lg-end">
          <div className="nav-btns">
            <a
              href="#"
              className="butn border rounded-pill border-black1 hover-bg-black1 text-uppercase color-2626"
            >
              <span> schedule a visit </span>
            </a>
            <a href="#" className="icon ms-4 side_menu_btn">
              <span>
                <i className="la la-grip-lines"></i>
              </span>
            </a>
          </div>
        </div> */}
      </div>
    </nav>
  );
}

export default Navbar;
