import React from 'react';
function Spacer() {
  return (
    <section className="tc-spacer-style5">
      
    </section>
  );
}

export default Spacer;
