import React from 'react';



function GoogleMap() {
  return (
    <div>
      <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1hU5vDoRAgYh8AD1ao514tJHyJjF2Qgc&ehbc=2E312F&noprof=1" title='locationMap' width="100%" height="640" style={{ marginTop: "-60px", }} ></iframe>
    </div>
  );
}

export default GoogleMap;
